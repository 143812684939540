import React from 'react'


const Children = () => {
    return (
        <>
            <p>
                W związku z tym, że okres nastoletni, który stanowi pomost pomiędzy dzieciństwem i dorosłością, jest
                trudnym wyzwaniem rozwojowym zarówno dla rodziców, jak i dla nastoletnich dzieci, psychoterapia
                proponuje repertuar metod i technik pracy dostosowanych do problemów tego wieku.
            </p>
            <p>Do dominujących trudności należą tutaj:</p>
            <ul>
                <li>zaburzenia w obszarze społeczno-emocjonalnym, np. napięcie, niepokój, impulsywność, zachowania
                    agresywne, brak cierpliwości, nieśmiałość, wycofanie z kontaktów społecznych;
                </li>
                <li>niskie poczucie własnej wartości;</li>
                <li>zaburzenia zachowania, np. zachowania antyspołeczne, przemoc rówieśnicza;</li>
                <li>trudności wynikające z sytuacji życiowych, np. choroba, rozstanie z partnerem/partnerką (pierwsze
                    doświadczenia miłosne, erotyczne), rozwód rodziców, niepowodzenia szkolne – w tym trudności z
                    koncentracją, z motywacją;
                </li>
                <li>slabilność (chwiejność) emocjonalna, manifestująca się dużymi wahaniami nastroju;</li>
            </ul>
        </>
    )
}

export default Children
