import React from 'react'


const Adults = () => {
    return (
        <>
            <p>
                Praca terapeutyczna w nurcie psychodynamicznym rozpoczyna się od kilku spotkań konsultacyjnych, podczas
                których rozpoznawane są problemy pacjentki/pacjenta oraz uzgadniania jest najlepsza dla niej/dla niego
                forma pracy terapeutycznej. Odbywa się ona raz lub dwa razy w tygodniu podczas spotkań trwających po 50
                minut. Proces terapeutyczny może trwać od kilku miesięcy do kilku lat, a jego celem jest pomoc
                pacjentce/pacjentowi we wnikliwszym rozumieniu siebie: swoich myśli, uczuć, zachowań i zaobserwowanie
                ich w różnych sytuacjach, w stosunku do różnych ludzi i u siebie samej/samego. Rozumienie to ma
                umożliwić dokonywanie pożądanych zmian w życiu.
            </p>
        </>
    )
}

export default Adults
