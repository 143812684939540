import React from 'react'
import styled from 'styled-components'
import LogoIcon from '../icons/LogoIcon'
import { isMobile } from 'react-device-detect'

const Logo = () => {
    return (
        <Container href='/#start'>
            <LogoIcon/>
            <TextContainer>
                <Title>Katarzyna Sochaj</Title>
                <Subtitle>psychoterapia</Subtitle>
            </TextContainer>
        </Container>
    )
}

export default Logo

const Container = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${isMobile ? '120px' : '150px'};
  margin: 0 0 0 10px;
`

const Title = styled.h1`
  font-size: ${isMobile ? '18px' : '24px'};
  font-weight: 700;
  line-height: 28px;
  padding: 0;
  margin: 0;
`

const Subtitle = styled.h2`
  font-size: ${isMobile ? '14px' : '15px'};
  font-weight: 400;
  padding: 0;
  margin: 0;
`
