import React, {ReactElement, useState} from 'react'
import styled from 'styled-components'
import Pager from './Pager'
import ArrowRight from './Icons/ArrowRight'
import ArrowLeft from './Icons/ArrowLeft'
import {isMobile} from 'react-device-detect'

interface IServiceInfoProps {
    index: number
    title: string
    description: ReactElement
    description2?: ReactElement
    animation: string
    id: string
    img: any
}

const ServiceInfo = (props: IServiceInfoProps) => {
    const {index, title, description, animation, id, description2, img} = props
    const [togglePage, setTogglePage] = useState(false)

    if (isMobile) {
        return (
            <ContainerMobile id={`service-${id}`}>
                <Image style={{margin: 'auto'}} src={img}/>
                <MobileContent>
                    <NumberMobile>{id}</NumberMobile>
                    <TitleMobile>{title}</TitleMobile>
                    <DescContainer>
                        {description}
                        {!!description2 && description2}
                    </DescContainer>
                </MobileContent>
            </ContainerMobile>
        )
    }

    return (
        <Container id={`service-${id}`}>
            <Content>
                <Pager activeIndex={index}/>
                <Line/>
                <Title>{title}</Title>
                <DescContainer>
                    {!togglePage && <Description>{description}</Description>}
                    {(!!description2 && togglePage) && <Description>{description2}</Description>}
                </DescContainer>
                {/*<Button>Umów wizytę</Button>*/}
                {!!description2 && <PagerContainer>
                    {!togglePage ? <ArrowRight onClick={() => setTogglePage(true)}/> :
                        <ArrowLeft onClick={() => setTogglePage(false)}/>}
                    {!togglePage ? <PageCounter>1/2</PageCounter> : <PageCounter>2/2</PageCounter>}
                </PagerContainer>}
            </Content>
            <Video autoPlay muted loop>
                <source src={animation} type="video/mp4"/>
            </Video>
        </Container>
    )
}

export default ServiceInfo

const PagerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
`

const PageCounter = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
`

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  max-height: calc(100vh - 170px);
  bottom: 0;
`

const ContainerMobile = styled.div`
  width: 100vw;
  overflow-x: hidden;
`

const Video = styled.video`
  border: none;
  outline: none;
  margin-bottom: -8px;
  margin-top: -1px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  max-width: 50%;
  height: auto;
  @media (max-width: 1250px) {
    max-width: 40%;
  }
  @media (max-width: 800px) {
    display: none;
  }
`

const Content = styled.div`
  padding: 0 50% 87px 7%;
  @media (max-width: 1250px) {
    padding-right: 40%;
  }
  @media (max-width: 800px) {
    padding-right: 7%;
  }
`

const MobileContent = styled.div`
  padding: 16px 10%;
  overflow-x: hidden;
`

const Line = styled.div<{ white?: boolean }>`
  height: 1px;
  background-color: ${props => props.white ? '#fff' : '#000'};
  margin-bottom: 30px;
`


const Title = styled.h4`
  margin: 30px 0;
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
  @media (max-width: 1250px) {
    font-size: 40px;
    word-break: break-all;
  }
  @media (max-width: 800px) {
    font-size: 25px;
    word-break: break-all;
  }
`

const TitleMobile = styled.h4`
  font-size: 40px;
  margin: 0;
`

const NumberMobile = styled.div`
  font-size: 64px;
  font-weight: 400;
`

const DescContainer = styled.div<{ white?: boolean }>`
  display: flex;
  color: ${props => props.white ? '#fff' : '#000'};
  flex-direction: ${isMobile ? 'column' : 'row'};
  ul {
    padding-left: 14px;
    margin-bottom: 14px;
  }
`

const Description = styled.div`
  overflow-x: hidden;
  margin: 0;
  font-size: 16px;
  padding-right: 20px;
  column-count: 2;
  column-gap: 1%;
  align-content: start;
  @media (min-width: 1200px) {
    max-height: calc(40vh);
  }


  & > * {
    margin-top: 0;
  }
  ul {
    padding-left: 18px;
    margin-bottom: 0;
  }
`

const Image = styled.img`
  width: 100%;
`
