import React, {useState} from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import {NavLink, NavLinkProps} from 'react-router-dom'
import {isMobile} from 'react-device-detect'
import Menu from './Icons/Menu'
import Close from './Icons/Close'

const Navbar = () => {
    const [opened, setOpened] = useState(false)
    if (isMobile) {
        return (
            <>
                <MobileContainer>
                    <Logo/>
                    <ButtonContainer onClick={() => setOpened(!opened)}>
                        {opened ? <Close/> : <Menu/>}
                    </ButtonContainer>
                </MobileContainer>
                {opened && <MobileMenuContainer>
                        <StyledLinkMobile onClick={() => setOpened(false)} exact={true} to="/#start">Oferta</StyledLinkMobile>
                        <StyledLinkMobile onClick={() => setOpened(false)} exact={true} to="/o-mnie">O mnie</StyledLinkMobile>
                        <StyledLinkMobile onClick={() => setOpened(false)} exact={true} to="/wizyty">Wizyty</StyledLinkMobile>
                        <StyledLinkMobile onClick={() => setOpened(false)} exact={true} to="/kontakt">Kontakt</StyledLinkMobile>
                </MobileMenuContainer>}
            </>
        )
    }
    return (
        <Container>
            <Logo/>
            <LinksContainer>
                <StyledLinkContainer><StyledLink onClick={() => window.location.href = window.location.origin + '/#start'} exact={true} to="/#start">Oferta</StyledLink></StyledLinkContainer>
                <StyledLinkContainer><StyledLink exact={true} to="/o-mnie">O mnie</StyledLink></StyledLinkContainer>
                <StyledLinkContainer><StyledLink exact={true} to="/wizyty">Wizyty</StyledLink></StyledLinkContainer>
                <StyledLinkContainer><StyledLink exact={true} to="/kontakt">Kontakt</StyledLink></StyledLinkContainer>
            </LinksContainer>
        </Container>
    )
}

export default Navbar

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 34px 8.9% 40px;
  background-color: #fff;
  position: fixed;
  width: 100vw;
  top: 0;
`

const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
  align-items: center;
`

const LinksContainer = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 40%;
`

const StyledLinkContainer = styled.li`
  margin: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const StyledLink = styled(NavLink)<NavLinkProps>`
  color: #000;
  text-decoration: none;
  font-size: 20px;

  &.active {
    font-weight: 700;
  }
`

const StyledLinkMobile = styled(NavLink)<NavLinkProps>`
  color: #000;
  text-decoration: none;
  font-size: 40px;

  &.active {
    font-weight: 700;
  }
`

const ButtonContainer = styled.div`
  padding: 16px;
`

const MobileMenuContainer = styled.div`
  background: #fff;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 115px);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 80px;
  align-items: center;
`
