import React from 'react'
import styled from 'styled-components'
import ArrowLeft from './Icons/ArrowLeft'
import ArrowRight from './Icons/ArrowRight'

interface IPagerProps {
    activeIndex: number
    white?: boolean
}

const list = ['01.', '02.', '03.', '04.', '05.']

const Pager = (props: IPagerProps) => {
    const {activeIndex, white} = props
    return (
        <Container>
            <a href={`#service-0${activeIndex ? activeIndex : 5}.`}>
                <ArrowLeft />
            </a>
            {list.map((val, i) => <Number white={white} href={`#service-${val}`} key={val}
                                          active={i === activeIndex}>{val}</Number>)}
            <a href={`#service-0${activeIndex !== 4 ? activeIndex + 2 : 1}.`}>
                <ArrowRight />
            </a>
        </Container>
    )
}

export default Pager

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  margin-top: 20px;
`

const Number = styled.a<{ active?: boolean, white?: boolean }>`
  font-size: ${props => props.active ? 48 : 40}px;
  margin: 0 10px 16px;
  font-weight: ${props => props.active ? 600 : 400};
  align-self: flex-end;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.white ? '#fff' : '#000'};

  &:hover {
    text-decoration: underline;
  }
`