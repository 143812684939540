import React from 'react'
import styled from 'styled-components'

interface IServiceHeaderProps {
    id: string
    title: string
    img: string
}

const MobileServiceHeader = (props: IServiceHeaderProps) => {
    const {id, title, img} = props
    return (
        <Container href={`#service-${id}`} img={img}>
                <Title>{title}</Title>
        </Container>
    )
}

export default MobileServiceHeader

const Container = styled.a<{img: string}>`
  width: 299px;
  height: 121px;
  display: block;
  background-image: url("${props => props.img}");
  text-decoration: none;
  margin-bottom: 15px;
  &:nth-child(5) {
    margin-bottom: 100px;
  }
`

const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  background: #fff;
  color: #000;
  line-height: 28px;
  position: relative;
  top: 32px;
`
