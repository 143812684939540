import React, {useState} from 'react'
import {IArrowProps} from './ArrowRight'

function ArrowLeft({onClick, style}: IArrowProps) {
    const [hoover, setHoover] = useState<boolean>(false)
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="24"
            fill="none"
            viewBox="0 0 50 24"
            style={{cursor: 'pointer', ...style}}
            onClick={onClick}
            onMouseOver={() => setHoover(true)}
            onMouseLeave={() => setHoover(false)}
        >
            <path
                fill={hoover ? '#F3F810' : '#000'}
                d="M1.831 13.823L12.055 24l1.83-1.823-8.927-8.887H50v-2.58H4.958l8.928-8.887L12.055 0 1.83 10.177 0 12l1.831 1.823z"
            />
        </svg>
    )
}

export default ArrowLeft
