import React from 'react'
import styled from 'styled-components'

interface IServiceHeaderProps {
    id: string
    title: string
    animation: string
}

const ServiceHeader = (props: IServiceHeaderProps) => {
    const {id, title, animation} = props
    return (
        <Container href={`#service-${id}`}>
            <VideoContainer>
                <Video autoPlay muted loop>
                    <source src={animation} type="video/mp4"/>
                </Video>
            </VideoContainer>
            <TextContainer>
                <Number>{id}</Number>
                <Title>{title}</Title>
            </TextContainer>
        </Container>
    )
}

export default ServiceHeader

const Container = styled.a`
  width: 20%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #000;
  &:hover {
    text-decoration: underline;
  }
`

const Video = styled.video`
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: -8px;
  margin-top: -1px;
`

const VideoContainer = styled.div`
  overflow: hidden;
`

const TextContainer = styled.div`
  padding: 16px;
  box-sizing: border-box;
  border-right: 1px solid black;
  margin: 0;
  height: 26%;
`

const Number = styled.span`
  display: block;
  font-weight: 400;
  font-size: 26px;
`

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 26px;
  width: 90%;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`