import React from 'react'

const Close = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" width={32} height={32}
             viewBox="0 0 96 96" enable-background="new 0 0 96 96">
            <polygon fill="black" points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
        </svg>
    )
}

export default Close
