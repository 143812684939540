import React from 'react'


const Adults2 = () => {
    return (
        <>
            <p>
                Psychoterapią leczone są następujące zaburzenia/trudności:

            </p>
            <ul>
                <li>zaburzenia nerwicowe związane ze stresem, np. zaburzenia lękowe, fobie, reakcje na stres, natrętne
                    myśli;
                </li>
                <li>dolegliwości psychosomatyczne mające postać, np. problemów skórnych, bólów: w klatce piersiowej,
                    głowy, żołądka;
                </li>
                <li>zaburzenia nastroju, np. epizody depresyjne, depresje nawracające;</li>
                <li>zaburzenia snu w postaci bezsenności, lęków nocnych, koszmarów sennych;</li>
                <li>zaburzenia osobowości, np. narcystyczne, obsesyjno-kompulsywne, borderline;</li>
                <li>zaburzenia odżywiania, np. bulimia, anoreksja;</li>
                <li>zaburzenia seksualne;</li>
            </ul>
        </>
    )
}

export default Adults2
