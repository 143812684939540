import React from 'react'


const Mediation = () => {
    return (
        <>
            <p>
                Zdarzenia życiowe i zmiany w naszym funkcjonowaniu na co dzień, wynikające z naszego rozwoju czy nowych
                sytuacji, z którymi trzeba wspólnie się zmierzyć, rodzą konflikty, do rozwiązania których nie
                wystarczają rozmowy w gronie rodzinnym. Może się wręcz wydawać, że próby porozumienia prowadzą do
                zaognienia sporów, a intensywność emocji jest taka duża, że brakuje nie tylko sposobów na porozumienie,
                ale też energii, by próbować rozwiązać trudność.
            </p>
        </>
    )
}

export default Mediation
