import React from 'react'


const PolishLanguage = () => {
    return (
        <>
            <p>
                Jako wieloletnia nauczycielka języka polskiego, egzaminatorka sprawdzianu po szkole podstawowej i
                egzaminu maturalnego, badaczka języka oraz psycholog opracowałam program pracy terapeutycznej połączonej
                z indywidualną nauką języka polskiego, zwłaszcza w obszarze różnych trudności rozwojowych, zaburzeń czy
                problemów z motywacją.
            </p>
            <p>
                Integrowanie kontaktu terapeutycznego z pracą nad rozwojem językowym i postępami w szkole przynosi
                bardzo duże efekty zarówno w zakresie osiągnięć szkolnych, jak i postępów w poprawie ogólnego
                funkcjonowania w obszarze emocjonalnym i psychospołecznym.
                <br/><br/><br/>
            </p>
        </>
    )
}

export default PolishLanguage
