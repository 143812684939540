import { useEffect } from 'react';
import '../styles/bookero.scss'

const useScript = (url: string) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;

        document.body.appendChild(script);
        console.log(script)

        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};

export default useScript;