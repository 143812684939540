import React from 'react'


const Mediation2 = () => {
    return (
        <>
            <p>
                Mediacje pozwalają na udrożnienie komunikacji pomiędzy stronami konfliktu, a stosowana metoda
                Porozumienia bez Przemocy (NVC) sprawia, że dokonuje się to w atmosferze zrozumienia dla wszystkich
                zaangażowanych w sytuację konfliktową uczestników spotkania. Jednocześnie uczestnicy mediacji uzyskują
                świadomość swoich potrzeb i oczekiwań w relacjach z innymi oraz potrzeb i oczekiwań innych osób.
            </p>
            <p>
                Praca mediacyjna rozpoczyna się od konsultacji z osobą/osobami zgłaszającymi problem, następnie z
                indywidualnych jednorazowych rozmów z każdą ze stron konfliktu, a wreszcie mediacji właściwych, podczas
                których spotykają się wszyscy zaangażowani w problem. Na mediacje zapraszam:
            </p>
            <ul>
                <li>rodziny, które przeżywają różne konflikty, np. bieżące, związane z rozwodem, podziałem majątku,
                    ustaleniem opieki nad dziećmi;
                </li>
                <li>pary;</li>
                <li>dzieci/młodzież (mediacje rówieśnicze);</li>
            </ul>
        </>
    )
}

export default Mediation2
