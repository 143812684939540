import React from 'react'
import { isMobile } from 'react-device-detect'

function LogoIcon() {
    return (
        <svg
            style={{width: isMobile ? 50 : 72}}
            xmlns="http://www.w3.org/2000/svg"
            width="72px"
            height="77"
            fill="none"
            viewBox="0 0 60 77"
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M28.357 6.723C30.852 3.306 37.927 0 40.662 0c-8.13 0-15.52 2.241-22.17 6.723-3.326 2.204-6.024 4.96-8.093 8.266-2.07 3.233-3.104 6.944-3.104 11.132 0 6.466 2.513 11.72 7.538 15.76a38.197 38.197 0 006.873 4.52 87.302 87.302 0 016.984 3.857c2.143 1.25 3.917 2.682 5.32 4.299 1.479 1.616 2.218 3.563 2.218 5.841 0 2.204-.868 6.227-3.16 8.211-4.156 4.684-11.122 7.715-13.856 7.715 9.386 0 21.672-1.671 28.766-6.668 3.252-2.278 5.839-4.96 7.76-8.046 1.995-3.16 2.993-6.576 2.993-10.25 0-5.437-2.55-10.176-7.649-14.217a60.21 60.21 0 00-7.094-4.85 148.935 148.935 0 01-7.095-4.409c-2.217-1.469-4.065-2.975-5.543-4.518l-.266-.306c-1.454-1.669-3.868-4.44-4.39-6.252-1.303-4.535-.067-7.714 1.663-10.085zm31.205 4.307a9.54 9.54 0 01-9.541 9.54 9.54 9.54 0 01-9.54-9.54 9.54 9.54 0 1119.08 0zM9.54 76.324a9.54 9.54 0 100-19.08 9.54 9.54 0 000 19.08z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default LogoIcon
