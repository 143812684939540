import React from 'react'
import styled from 'styled-components'
import useScript from '../_utils/useScript'

const Appointments = () => {
    useScript('https://internal.bookero.pl/plugin/v2/js/bookero-compiled.js')
    return (
        <Container>
                <div id="bookero"/>
        </Container>
    )
}

export default Appointments

const Container = styled.div`
  height: calc(100vh - 155px);
  overflow-y: auto;
`

const Inner = styled.div`
  max-width: 800px;
  margin: auto;
`