import React, {HTMLProps, useState} from 'react'

export interface IArrowProps extends HTMLProps<SVGSVGElement> {}

function ArrowRight({onClick, style}: IArrowProps) {
    const [hoover, setHoover] = useState<boolean>(false)
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="24"
            fill="none"
            viewBox="0 0 50 24"
            style={{cursor: 'pointer', ...style}}
            onClick={onClick}
            onMouseOver={() => setHoover(true)}
            onMouseLeave={() => setHoover(false)}
        >
            <path
                fill={hoover ? '#F3F810' : '#000'}
                d="M48.169 13.823L37.946 24l-1.832-1.823 8.928-8.887H0v-2.58h45.042l-8.928-8.887L37.946 0l10.223 10.177L50 12l-1.831 1.823z"
            />
        </svg>
    )
}

export default ArrowRight
