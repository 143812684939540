import React from 'react'


const Consultancy = () => {
    return (
        <>
            <p>
                Konsultacja/pomoc psychologiczna służy przyjrzeniu się bieżącym problemom, stanowi próbę zdiagnozowania
                ich przyczyn, a jej efektem jest znalezienie najlepszych rozwiązań. Może to być na przykład cykl spotkań
                służących psychoedukacji, wybór psychoterapii, socjoterapii czy innej formy pomocy.
            </p>
            <p>
                Konsultacje mogą pełnić również funkcję interwencyjną – kiedy osoba zgłaszająca się po pomoc nie jest w
                stanie samodzielnie poradzić sobie z aktualnymi trudnościami, np. z przemocą, myślami samobójczymi,
                traumami.
            </p>
            <p>Pomoc psychologiczna adresowana jest do osób w każdym wieku, zarówno dzieci, jak i seniorów. W przypadku
                dzieci i młodzieży możliwe jest zdiagnozowane trudności szkolnych, emocjonalnych czy społecznych. Z
                kolei seniorzy mogą otrzymać wsparcie w poprawie codziennego funkcjonowania w różnych obszarach
                aktywności.</p>
            <p> Konsultacje psychologiczne są skierowane są docelowo do osób potrzebujących porady i diagnozy
                problemów w kwestiach rodzinnych, małżeńskich, wychowawczych, zawodowych, związanych z trudnymi
                zmianami lub konkretnym okresem życia.
            </p>
        </>
    )
}

export default Consultancy
