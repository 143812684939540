import React from 'react'


const Children2 = () => {
    return (
        <>
            <ul>
                <li>nieumiejętność wyboru drogi życiowej, trudności z rozumieniem siebie, swoich emocji, emocji innych
                    ludzi;
                </li>
                <li>uzależnienia, np. od komputera, substancji psychoaktywnych;</li>
                <li>zaburzenia nastroju, zwłaszcza obniżenie nastroju i tzw. negatywizm;</li>
                <li>kryzysy suicydalne: myśli samobójcze, samouszkodzenia, próby samobójcze;</li>
                <li>zaburzenia jedzenia, np. anoreksja, bulimia;</li>
                <li>zaburzenia nerwicowe i psychosomatyczne;</li>
                <li>inne – podobne jak w przypadku osób dorosłych;</li>
            </ul>
            <p>Celem pracy z osobą nastoletnią jest jej wsparcie w uzyskiwaniu lepszej kontroli impulsów i większej
                równowagi emocjonalnej, co ma stanowić wsparcie adolescenta w uzyskaniu zdolności do poradzenia sobie z
                rosnącymi wymaganiami społecznymi: stawianymi przez szkołę, a w przyszłości związanymi z życiem
                zawodowym i relacjami intymnymi.</p>
            <p>Poza psychoterapią psychodynamiczną stosuję w pracy z młodzieżą formy poznane podczas szkoleń z zakresu
                psychoterapii opartej na mentalizacji (MTB) i terapii skoncentrowanej na rozwiązaniu (TSR) czy dialogu
                motywującego.</p>
        </>
    )
}

export default Children2