import React from 'react'
import Navbar from './components/Navbar'
import Services from './pages/Services'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom'
import Contact from './pages/Contact'
import styled from 'styled-components'
import About from './pages/About'
import Appointments from './pages/Appointments'
import { isMobile } from 'react-device-detect'

function App() {
    return (
        <Router>
            <Navbar/>
            <BodyContainer>
                <Switch>
                    <Route path="/" exact={true}>
                        <Services/>
                    </Route>
                    <Route path="/kontakt">
                        <Contact/>
                    </Route>
                    <Route path="/wizyty">
                        <Appointments/>
                    </Route>
                    <Route path="/o-mnie">
                        <About/>
                    </Route>
                </Switch>
            </BodyContainer>
        </Router>
    )
}

export default App

const BodyContainer = styled.div`
  margin-top: ${isMobile ? '50px' : '153px'};
`