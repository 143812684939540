import React, {useEffect} from 'react'
import styled from 'styled-components'
import ServiceHeader from '../components/ServiceHeader'
import animation1 from '../assets/animations/1.mp4'
import animation2 from '../assets/animations/2.mp4'
import animation3 from '../assets/animations/3.mp4'
import animation4 from '../assets/animations/4.mp4'
import animation5 from '../assets/animations/5.mp4'
import animationBig1 from '../assets/animationsBig/1.mp4'
import animationBig2 from '../assets/animationsBig/2.mp4'
import animationBig3 from '../assets/animationsBig/3.mp4'
import animationBig4 from '../assets/animationsBig/4.mp4'
import animationBig5 from '../assets/animationsBig/5.mp4'
import ServiceInfo from '../components/ServiceInfo'
import Children from '../components/Children'
import Children2 from '../components/Children2'
import Adults from '../components/Adults'
import Adults2 from '../components/Adults2'
import Mediation from '../components/Mediation'
import Mediation2 from '../components/Mediation2'
import PolishLanguage from '../components/PolishLanguage'
import Consultancy from '../components/Consultancy'
import {isMobile} from 'react-device-detect'
import bg1 from '../assets/images/backgroundMobile/1.png'
import bg2 from '../assets/images/backgroundMobile/2.png'
import bg3 from '../assets/images/backgroundMobile/3.png'
import bg4 from '../assets/images/backgroundMobile/4.png'
import bg5 from '../assets/images/backgroundMobile/5.png'
import MobileServiceHeader from '../components/MobileServiceHeader'
const servicesList = [
    {
        animation: animation1,
        animationBig: animationBig1,
        title: 'Psychoterapia dzieci i młodzieży',
        id: '01.',
        description: <Children/>,
        description2: <Children2/>,
        img: bg1,
    },
    {
        animation: animation2,
        animationBig: animationBig2,
        title: 'Psychoterapia dorosłych',
        id: '02.',
        description: <Adults/>,
        description2: <Adults2/>,
        img: bg2,
    },
    {
        animation: animation3,
        animationBig: animationBig3,
        title: 'Mediacje rodzinne',
        id: '03.',
        description: <Mediation/>,
        description2: <Mediation2/>,
        img: bg3,
    },
    {
        animation: animation4,
        animationBig: animationBig4,
        title: 'Język polski z\u00A0elementami terapii',
        id: '04.',
        description: <PolishLanguage/>,
        img: bg4,
    },
    {
        animation: animation5,
        animationBig: animationBig5,
        title: 'Konsultacje psychologiczne',
        id: '05.',
        description: <Consultancy/>,
        img: bg5,
    }
]

const Services = () => {
    useEffect(() => {
        const listener = (e: any) => {
            e.preventDefault()
            if (window) {
                window.scrollBy(e.deltaY * 1.2, 0)
            }
        }
        if (window) {
            window.addEventListener('wheel', listener)
        }
        return () => {
            if (window) {
                window.removeEventListener('wheel', listener)
            }
        }
    }, [])

    if (isMobile) {
        return (
            <MobileContainer>
                {servicesList.map(val => <MobileServiceHeader key={val.id} id={val.id} title={val.title}
                                                              img={val.img} />)}
                {servicesList.map((val, i) => <ServiceInfo key={val.id} id={val.id} index={i} title={val.title}
                                                           description={val.description} description2={val.description2}
                                                           animation={val.animationBig} img={val.img} />)}
            </MobileContainer>
        )
    }

    return (
        <Container>
            <ServicesContainer id="start">
                {servicesList.map(val => <ServiceHeader key={val.id} id={val.id} title={val.title}
                                                        animation={val.animation}/>)}
            </ServicesContainer>
            {servicesList.map((val, i) => <ServiceInfo key={val.id} id={val.id} index={i} title={val.title}
                                                       description={val.description} description2={val.description2}
                                                       animation={val.animationBig} img={val.img} />)}
        </Container>

    )
}

export default Services

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 600vw;
  overflow-y: hidden;
`

const MobileContainer = styled.div`
    display: flex;
  flex-direction: column;
  align-items: center;
`

const ServicesContainer = styled.section`
  display: flex;
  width: 100vw;
`