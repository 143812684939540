import React from 'react'
import styled from 'styled-components'
import about from './../assets/images/about4.jpg'
import {isMobile} from 'react-device-detect'

const About = () => {
    if (isMobile) {
        return (
            <Container>
                <MobileContent>
                    <InfoContainer>
                        <p>Magister filologii polskiej i magister psychologii. Absolwentka studiów doktoranckich na
                            Uniwersytecie Gdańskim.</p>
                        <p> Prowadzę psychoterapię w nurcie psychodynamicznym, ale korzystam również z narzędzi innych
                            szkół terapeutycznych oraz umiejętności nabytych podczas wielu szkoleń i studiów, między
                            innymi z zakresu mediacji prowadzonych metodą Porozumienia bez Przemocy, Terapii
                            Skoncentrowanej na Rozwiązaniach, Terapii Systemowej oraz Psychoterapii Opartej na
                            Mentalizacji (MTB).</p>
                        <p>Od ponad dwudziestu lat zajmuję się pracą z dziećmi i młodzieżą oraz dorosłymi w obszarze
                            edukacji, wychowania, rozwoju kompetencji społecznych i psychologicznych. Jestem autorką
                            programów psychoedukacyjnych, profilaktycznych i wychowawczych. Prowadziłam również wykłady i
                            warsztaty dla nauczycieli i dyrektorów szkół w zakresie metod nauczania i metod diagnozowania
                            pracy dydaktyczno-wychowawczej oraz własne badania w tym obszarze. Aktualnie udzielam przede
                            wszystkim konsultacji psychologicznych i prowadzę leczenie psychoterapeutyczne.
                        </p>
                        <p>Należę do Polskiego Towarzystwa Psychoterapii Psychodynamicznej, Wielkopolskiego Towarzystwa
                            Terapii Systemowej i Polskiego Stwowarzyszenia Socjoterapeutów i Trenerów. Swoje umiejętności
                            psychoterapeutyczne rozwijam podczas kształcenia w Krakowskim Centrum Psychodynamicznym. Pracę z
                            pacjentami poddaję regularnej superwzji.</p>
                    </InfoContainer>
                </MobileContent>
                <ImageContainer>
                    <Image src={about}/>
                </ImageContainer>
                <MobileContent>
                    <TitleMobile>O mnie</TitleMobile>
                </MobileContent>
            </Container>
        )
    }
    return (
        <Container>
            <Content>
                <Title>O mnie</Title>
                <InfoContainer>
                    <p>Magister filologii polskiej i magister psychologii. Absolwentka studiów doktoranckich na
                        Uniwersytecie Gdańskim.</p>
                    <p> Prowadzę psychoterapię w nurcie psychodynamicznym, ale korzystam również z narzędzi innych
                        szkół terapeutycznych oraz umiejętności nabytych podczas wielu szkoleń i studiów, między
                        innymi z zakresu mediacji prowadzonych metodą Porozumienia bez Przemocy, Terapii
                        Skoncentrowanej na Rozwiązaniach, Terapii Systemowej oraz Psychoterapii Opartej na
                        Mentalizacji (MTB).</p>
                    <p>Od ponad dwudziestu lat zajmuję się pracą z dziećmi i młodzieżą oraz dorosłymi w obszarze
                        edukacji, wychowania, rozwoju kompetencji społecznych i psychologicznych. Jestem autorką</p>
                    <p>programów psychoedukacyjnych, profilaktycznych i wychowawczych. Prowadziłam również wykłady i
                        warsztaty dla nauczycieli i dyrektorów szkół w zakresie metod nauczania i metod diagnozowania
                        pracy dydaktyczno-wychowawczej oraz własne badania w tym obszarze. Aktualnie udzielam przede
                        wszystkim konsultacji psychologicznych i prowadzę leczenie psychoterapeutyczne.</p>
                    <p>Należę do Polskiego Towarzystwa Psychoterapii Psychodynamicznej, Wielkopolskiego Towarzystwa
                        Terapii Systemowej i Polskiego Stwowarzyszenia Socjoterapeutów i Trenerów. Swoje umiejętności
                        psychoterapeutyczne rozwijam podczas kształcenia w Krakowskim Centrum Psychodynamicznym. Pracę z
                        pacjentami poddaję regularnej superwzji.</p>
                </InfoContainer>
            </Content>
            <ImageContainer>
                <Image src={about}/>
            </ImageContainer>
        </Container>
    )
}

export default About

const Container = styled.div`
  display: flex;
  @media (min-width: 800px) {
    overflow-y: auto;
    max-height: calc(100vh - 170px);
  }
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`

const ImageContainer = styled.div`
  width: 50%;
  overflow: hidden;
  @media (max-width: 1200px) {
    width: 100%;
  }
`

const Image = styled.img`
  margin-top: ${isMobile ? '16px' : '150px'};
  max-width: 100%;
`

const Content = styled.div`
  padding: 50px 7% 0;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1201px) {
    max-width: 60%;
  }
  
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 70px;
  margin: 0;
  padding: 0;
`

const InfoContainer = styled.section`
  column-count: 2;
  column-gap: 10%;
  @media (max-width: 800px) {
    column-count: 1;
  }
  & > * {
    display: inline-block;
  }
`

const TitleMobile = styled.h4`
  font-size: 40px;
  margin: 0;
`

const MobileContent = styled.div`
  padding: 16px 10%;
  overflow-x: hidden;
`