import React from 'react'
import styled from 'styled-components'
import ContactBg from './../assets/animations/ContactBg.mp4'
import faceboook from './../assets/images/facebook.png'
import {isMobile} from 'react-device-detect'

const Contact = () => {
    if (isMobile) {
        return (
            <Container>
                <MobileContent>
                    <TitleMobile>Kontakt</TitleMobile>
                    <InfoHeader>Adres:</InfoHeader>
                    <InfoValue>Gabinet Witosławy 10/3, Gdynia</InfoValue>
                    <InfoHeader>Psychoterapia online:</InfoHeader>
                    <InfoValue>Skype, Zoom, WhatsApp, Messenger</InfoValue>
                    <div>
                        <InfoHeader>E-mail:</InfoHeader>
                        <Link href="mailto:gabinet@katarzynasochaj.pl">gabinet@katarzynasochaj.pl</Link>
                        <InfoHeader>Telefon:</InfoHeader>
                        <Link href="tel:+48727302304">+48 727 302 304</Link>
                    </div>
                    <FacebookContainer>
                        <a href="https://www.facebook.com/katarzynasochaj.gdynia/" target="_blank" rel="noreferrer">
                            <Facebook src={faceboook}/>
                        </a>
                    </FacebookContainer>
                </MobileContent>
            </Container>
        )
    }
    return (
        <Container>
            <Content>
                <Title>Kontakt</Title>
                <InfoContainer>
                    <Column>
                        <InfoHeader>Adres gabinetu:</InfoHeader>
                        <InfoValue>Gdynia, Witosławy 10/3</InfoValue>
                        <InfoHeader>Psychoterapia online:</InfoHeader>
                        <InfoValue>Skype, Zoom, WhatsApp, Messenger</InfoValue>
                    </Column>
                    <div>
                        <InfoHeader>E-mail:</InfoHeader>
                        <Link href="mailto:gabinet@katarzynasochaj.pl">gabinet@katarzynasochaj.pl</Link>
                        <InfoHeader>Telefon:</InfoHeader>
                        <Link href="tel:+48727302304">+48 727 302 304</Link>
                    </div>
                </InfoContainer>
                <FacebookContainer>
                    <a href="https://www.facebook.com/Katarzyna.Sochaj.Psychoterapia.Gdynia" target="_blank" rel="noreferrer">
                        <Facebook src={faceboook}/>
                    </a>
                </FacebookContainer>
            </Content>
            <Video autoPlay muted loop>
                <source src={ContactBg} type="video/mp4"/>
            </Video>
        </Container>
    )
}

export default Contact

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100vh - 150px);
  bottom: 0;
`

const Video = styled.video`
  border: none;
  outline: none;
  margin-bottom: -8px;
  margin-top: -1px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  max-width: 50%;
  height: auto;
`

const Content = styled.div`
  padding: 50px 50% 87px 7%;
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 70px;
  margin: 0;
  padding: 0;
`

const InfoContainer = styled.section`
  display: flex;
  justify-content: space-between;
`

const Column = styled.div`
  max-width: 350px;
`

const InfoHeader = styled.h6`
  margin: 30px 0;
  font-size: 24px;
  font-weight: 700;
  @media (max-width: 800px) {
    font-size: 20px;
  }
`

const InfoValue = styled.p`
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: 400;
  @media (max-width: 800px) {
    font-size: 20px;
  }
`

const Link = styled.a`
  text-decoration: none;
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: 400;
  color: #000;
  @media (max-width: 800px) {
    font-size: 20px;
  }
`

const TitleMobile = styled.h4`
  font-size: 40px;
  margin: 0;
`

const MobileContent = styled.div`
  padding: 16px 10% 64px;
  overflow-x: hidden;
`

const FacebookContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
  cursor: pointer;
`

const Facebook = styled.img`
  width: 72px;
`